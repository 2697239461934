





























































































































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

import VueSlickCarousel from 'vue-slick-carousel'

export default defineComponent({
    name: 'SlickCards',
    components: {
        VueSlickCarousel
    },
    inject: [
        'image_format'
    ],
    setup() {
        const image_prefix = "https://res.cloudinary.com/kitchens-bedrooms/image/upload/" //process.env.NUXT_IMAGE_PROVIDER_BASE_URL

        return {
            image_prefix
        }
    },
    props: {
        padding: {
            type: String,
            default: 0
        },
        rowCols: {
            type: Object,
            default () {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 3
                    }
                }
            }
        },
        background: String,
        title: String,
        description: String,
        content: {
            type: Object,
            default() {
                return {
                    title: String,
                    description: String,
                    cards: {
                        type: Array,
                        default() {
                            return {
                                image: {
                                    type: Object,
                                    default() {
                                        return {
                                            src: String,
                                            width: Number,
                                            height: Number,
                                            alt: String
                                        }
                                    }
                                },
                                cutout: String,
                                title: String,
                                description: String,
                                link_title: String,
                                link_url: String
                            }
                        }
                    },
                    tabs: {
                        type: Object,
                        default() {
                            return {
                                title: String,
                                cards: {
                                    type: Array,
                                    default() {
                                        return {
                                            image: {
                                                type: Object,
                                                default() {
                                                    return {
                                                        src: String,
                                                        width: Number,
                                                        height: Number,
                                                        alt: String
                                                    }
                                                }
                                            },
                                            cutout: String,
                                            title: String,
                                            description: String,
                                            link_title: String,
                                            link_url: String
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        cta: {
            type: Object,
            default() {
                return {
                    title: String,
                    url: {
                        type: Object,
                        default() {
                            return {
                                name: 'home'
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            settings: {
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                cssEase: "ease-in-out",
                centerMode: true,
                centerPadding: '0px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '30px',
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '30px',
                            infinite: false
                        }
                    }
                ]
            }
        }
    },
    methods: {
        slugify(str) {                
            return str
                .toString()
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');
        
        },
    }
});
