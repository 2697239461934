import { render, staticRenderFns } from "./ImageCopy.vue?vue&type=template&id=6b6bc710&scoped=true&"
import script from "./ImageCopy.vue?vue&type=script&lang=ts&"
export * from "./ImageCopy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6bc710",
  null
  
)

export default component.exports