var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-contact-form-details wide-container my-5"},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-column flex-md-row gap-4 gap-md-0 align-items-center justify-content-between"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.data.description)}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"d-flex align-items-center gap-3 h5"},[_c('i',{staticClass:"fa-light fa-phone larks-stone"}),_c('a',{staticClass:"text-transform-none",attrs:{"href":("tel:" + (_vm.data.phone))}},[_vm._v(_vm._s(_vm.data.phone))])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center gap-3 h5"},[_c('i',{staticClass:"fa-regular fa-envelope larks-stone"}),_c('a',{staticClass:"text-lowercase",attrs:{"href":("mailto:" + (_vm.data.email))}},[_vm._v(_vm._s(_vm.data.email))])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center gap-3 h5"},[_c('i',{staticClass:"fa-regular fa-location-dot larks-stone"}),_c('div',{staticClass:"text-transform-none",domProps:{"innerHTML":_vm._s(_vm.data.address)}})]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.data.further_details)}})])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"border-radius background-pastel-pink-40 p-4"},[(_vm.showForm)?[_vm._m(0),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.formAction.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-13"},[_vm._v("Name "),_c('span',{staticClass:"larks-red"},[_vm._v("*")])]),_vm._v(" "),_c('SfInput',{staticClass:"form__element",attrs:{"type":"text","name":"name","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,false,1971735939)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-13"},[_vm._v("Email address: "),_c('span',{staticClass:"larks-red"},[_vm._v("*")])]),_vm._v(" "),_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email"},on:{"keyup":function($event){errors.length > 0 ? (_vm.userEmailErr = true) : (_vm.userEmailErr = false)}},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})]}}],null,false,1035813458)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-13"},[_vm._v("Phone number: "),_c('span',{staticClass:"larks-red"},[_vm._v("*")])]),_vm._v(" "),_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-phone'),expression:"'login-modal-phone'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"phone"},on:{"keyup":function($event){errors.length > 0 ? (_vm.userPhoneErr = true) : (_vm.userPhoneErr = false)}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}})]}}],null,false,830471287)}),_vm._v(" "),_c('div',{staticClass:"required"},[_c('label',{staticClass:"font-13"},[_vm._v("Message")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"type":"text","name":"message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{attrs:{"type":"text","name":"message","valid":!errors[0],"error-messages":"This field is required"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,false,3546908345)})],1),_vm._v(" "),_c('p',{staticClass:"smallest my-3"},[_vm._v("\n                                    By submitting this form you agree we will hold and store your information\n                                    in line with GDPR regulations."),_c('br'),_vm._v("\n                                    For more information see our\n                                    "),_c('nuxt-link',{attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v("Privacy Policy")]),_vm._v(".\n                                ")],1),_vm._v(" "),_c('client-only',[_c('recaptcha',{on:{"error":_vm.onError,"success":_vm.onSuccess,"expired":_vm.onExpired}})],1),_vm._v(" "),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('button',{staticClass:"btn small primary semi-bold border-radius round",attrs:{"disabled":_vm.name == '' ||
                                            _vm.userEmail == '' ||
                                            _vm.userPhone == '' ||
                                            _vm.userEmailErr == true ||
                                            _vm.userPhoneErr == true ||
                                            _vm.message == '' ||
                                            _vm.recaptcha == false,"type":"submit"}},[_vm._v("\n                                            Contact us\n                                        ")])]}}],null,false,4080538358)})],1)],1)]:[_vm._m(1)]],2)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex gap-3 align-items-center w-100 mb-3"},[_c('div',{},[_c('div',{staticClass:"border-radius round background-white icon-wrapper shadow"},[_c('i',{staticClass:"small fa-solid fa-calendar-days"})])]),_vm._v(" "),_c('div',{staticClass:"flex-fill text-start"},[_c('h3',[_vm._v("Contact our support team")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h4',[_c('i',{staticClass:"fa-solid fa-check larks-positive-green"}),_vm._v(" Thank you for getting in touch")]),_vm._v(" "),_c('p',{staticClass:"max-width mx-auto"},[_vm._v("We’ve received your message. A member of our team will be in touch within the next 24 hours.")])])}]

export { render, staticRenderFns }