































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'Breadcrumbs',
    components: {
        
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        // @ts-ignore
        return {
            
        };
    },
    data() {
        return {
            
        }
    },
    props: {
        breadcrumbs: Array
    }
});
