var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cards wide-container"},[_c('div',{class:(_vm.background + " " + _vm.padding)},[(_vm.content.title || _vm.content.description)?[_c('div',{staticClass:"content-container"},[(_vm.content.title)?[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col text-center"},[_c('h2',[_vm._v(_vm._s(_vm.content.title))])])])]:_vm._e(),_vm._v(" "),(_vm.content.description)?[_c('div',{staticClass:"max-width mx-auto text-center mb-3",domProps:{"innerHTML":_vm._s(_vm.content.description)}})]:_vm._e()],2)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-container pt-0 pb-3"},[(_vm.content && _vm.content.cards && typeof _vm.content.cards !== 'undefined' && _vm.content.cards.length > 0)?[_c('VueSlickCarousel',_vm._b({staticClass:"padded mt-3 mb-5",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]}}],null,false,1706010244)},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.content.cards),function(ref,cardIndex){
var image = ref.image;
var cutout = ref.cutout;
var title = ref.title;
var description = ref.description;
var url = ref.url;
var button = ref.button;
return _c('div',{key:(title + "-" + cardIndex),staticClass:"slick-item"},[_c('div',{staticClass:"card shadow radius h-100"},[(image)?_c('div',{staticClass:"card-img",class:{'lower-cutout': cutout === '“'},attrs:{"data-after":cutout}},[_c('nuxt-link',{attrs:{"to":url}},[_c('nuxt-img',{staticClass:"card-img-top",attrs:{"src":(_vm.image_prefix + "c_fill,w_438,h_299/" + (_vm.image_format.name) + ",q_70/" + (image.src)),"alt":image.alt,"loading":"lazy"}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_c('nuxt-link',{attrs:{"to":url}},[_vm._v(_vm._s(title))])],1),_vm._v(" "),_c('p',{staticClass:"card-text font-13"},[_vm._v(_vm._s(description))])]),_vm._v(" "),(url)?_c('div',{staticClass:"card-footer"},[_c('nuxt-link',{attrs:{"to":url}},[_vm._v(_vm._s(button))])],1):_vm._e()])])}),0),_vm._v(" "),(_vm.content.url && _vm.content.cta)?_c('div',{staticClass:"text-center"},[_c('nuxt-link',{staticClass:"btn border-radius round small semi-bold primary text-uppercase mx-auto",attrs:{"to":_vm.content.url}},[_vm._v(_vm._s(_vm.content. cta))])],1):_vm._e()]:_vm._e(),_vm._v(" "),(typeof _vm.content.tabs !== 'undefined')?[_c('b-tabs',{attrs:{"pills":"","content-class":"mt-3","align":"center"}},_vm._l((_vm.content.tabs),function(ref,tabIndex){
var title = ref.title;
var cards = ref.cards;
return _c('div',{key:(title + "-" + tabIndex)},[_c('b-tab',{attrs:{"title":title}},[_c('VueSlickCarousel',_vm._b({staticClass:"padded mt-3 mb-5",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]}}],null,true)},'VueSlickCarousel',_vm.settings,false),_vm._l((cards),function(ref,tabCardIndex){
var image = ref.image;
var cutout = ref.cutout;
var title = ref.title;
var description = ref.description;
var url = ref.url;
var button = ref.button;
return _c('div',{key:(title + "-" + tabIndex + "-" + tabCardIndex),staticClass:"slick-item"},[_c('div',{staticClass:"card shadow radius h-100"},[(image)?_c('div',{staticClass:"card-img",class:{'lower-cutout': cutout === '“'},attrs:{"data-after":cutout}},[_c('nuxt-img',{staticClass:"card-img-top",attrs:{"src":(_vm.image_prefix + "c_fill,w_438,h_299/" + (_vm.image_format.name) + ",q_70/" + (image.src)),"alt":image.alt,"loading":"lazy"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v(_vm._s(title))]),_vm._v(" "),_c('p',{staticClass:"card-text font-13"},[_vm._v(_vm._s(description))])]),_vm._v(" "),(url)?_c('div',{staticClass:"card-footer"},[_c('nuxt-link',{attrs:{"to":url}},[_vm._v(_vm._s(button))])],1):_vm._e()])])}),0),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('nuxt-link',{staticClass:"btn border-radius round small semi-bold primary text-uppercase mx-auto",attrs:{"to":{ name: _vm.content.url, params: { tab: tabIndex } }}},[_vm._v("View all inspiration for "+_vm._s(title))])],1)],1)],1)}),0)]:_vm._e()],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }