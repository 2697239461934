









































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent ({
    name: 'Downloads',
    components: {
        
    },
    data() {
        return {
            image_prefix: null
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    props: {
        data: {
            type: Array,
            default() {
                return {
                    title: String,
                    description: String,
                    image: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    },
                    cta: {
                        type: Object,
                        default() {
                            return {
                                label: String,
                                url: String
                            }
                        }
                    }
                }
            }
        }
    }
});
