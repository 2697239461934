








































































































export default {
    name: 'CardSection',
    components: {
        
    },
    inject: [
        'image_format'
    ],
    props: {
        padding: {
            type: String,
            default: ''
        },
        margin: {
            type: String,
            default: null
        },
        separator: {
            type: Boolean,
            default: false
        },
        paddedContent: {
            type: Boolean,
            default: false
        },
        rowCols: {
            type: Object,
            default () {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 3
                    }
                }
            }
        },
        background: String,
        title: String,
        description: String,
        cardDetails: {
            type: Array||Object,
            default() {
                return {
                    image: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    },
                    cutout: String,
                    title: String,
                    description: String,
                    price: String,
                    link_title: String,
                    link_url: String
                }
            }
        },
        cta: {
            type: Object,
            default() {
                return {
                    title: String,
                    url: {
                        type: Object,
                        default() {
                            return {
                                name: 'home'
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    }
}
