



























import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'Copy',
    components: {

    },
    setup(props, context) {
        if ( props.data.copy.length ) {
            var copy = props.data.copy
            if (process.client) {
                copy = copy.replace('<table>', '<div class="table-wrapper"><table>')
                copy = copy.replace('</table>', '</table></div>')
            }
            props.data.copy = copy
        }
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    copy: String,
                    alignment: String
                }
            }
        }
    }
})
