






















// import { v4 as uuidv4 } from 'uuid';
import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'Accordion',
    components: {
        
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        // @ts-ignore
        return {
            
        };
    },
    data() {

        // var uuid = null

        // if (!uuid) {
        //     uuid = uuidv4()
        // }

        return {
        //     uuid
        }
    },
    props: {
        accordionData: {
            type: [Array, Object],
            default() {
                return [
                    {
                        question: {
                            type: String,
                            default: 'q'
                        },
                        answer: {
                            type: String,
                            default: 'a'
                        }
                    }
                ]
            }
        },
        uuid: {
            type: String,
            default: null
        }
    }
});
