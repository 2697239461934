import { render, staticRenderFns } from "./_.vue?vue&type=template&id=06ab8723&"
import script from "./_.vue?vue&type=script&lang=ts&"
export * from "./_.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/var/www/components/Breadcrumbs.vue').default,CardSection: require('/var/www/components/CardSection.vue').default,Copy: require('/var/www/components/Copy.vue').default,Downloads: require('/var/www/components/Downloads.vue').default,FAQs: require('/var/www/components/FAQs.vue').default,FullImageCardBanner: require('/var/www/components/FullImageCardBanner.vue').default,HeroBanner: require('/var/www/components/HeroBanner.vue').default,ImageWave: require('/var/www/components/ImageWave.vue').default,TrustPilot: require('/var/www/components/TrustPilot.vue').default,IconBar: require('/var/www/components/IconBar.vue').default,ImageCopy: require('/var/www/components/ImageCopy.vue').default,SimpleBanner: require('/var/www/components/SimpleBanner.vue').default,SlickCards: require('/var/www/components/SlickCards.vue').default,Team: require('/var/www/components/Team.vue').default,TitleDescription: require('/var/www/components/TitleDescription.vue').default,VideoCopySplit: require('/var/www/components/VideoCopySplit.vue').default,Video: require('/var/www/components/Video.vue').default,ZoomCards: require('/var/www/components/ZoomCards.vue').default,ContactDetailsForm: require('/var/www/components/ContactDetailsForm.vue').default})
