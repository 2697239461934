





































































































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
// import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
// import { useContent } from '~/composables';
// import { CmsPage } from '~/modules/GraphQL/types';

export default defineComponent({
    name: 'Team',
    components: {
        LazyHydrate
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    items: {
                        type: Array,
                        default() {
                            return {
                                name: String,
                                role: String,
                                description: String,
                                links: {
                                    type: Array,
                                    default() {
                                        return {
                                            url: String,
                                            icon: String
                                        }
                                    }
                                },
                                image: {
                                    type: Object,
                                    default() {
                                        return {
                                            src: String,
                                            width: Number,
                                            height: Number,
                                            alt: String
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            hoverItem: false,
            activeItem: false,
            /*
            data: {
                members: [
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-1',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    },
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-2',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    },
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-3',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    },
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-4',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    },
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-5',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    },
                    {
                        name: 'Joe',
                        job_title: 'Managing Director',
                        content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><strong>Hobbies</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p><strong>Favourite Holiday Destination</strong><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>",
                        socials: [
                            {
                                icon: "fa-brands fa-linkedin-in",
                                url: 'https://linkedin.com/'
                            },
                            {
                                icon: 'fa-solid fa-envelope',
                                url: 'https://linkedin.com/'
                            }
                        ],
                        image: {
                            src: 'https://picsum.photos/400/400?test=team-member-6',
                            width: 400,
                            height: 400,
                            alt: 'Lorem ipsum dolor sit amet'
                        }
                    }
                ],
            },
            */
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    }
});
