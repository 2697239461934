






































































































































































































// import {
//     defineComponent,
//     ref,
//     useContext,
//     onMounted,
//     useFetch,
// } from '@nuxtjs/composition-api';
// import { 
//   DynamicPage,
//   DynamicComponent
// } from '~/modules/GraphQL/types';
import { useContent } from '~/composables';

// import { onSSR } from '@vue-storefront/core';
import {
    useCache,
    // CacheTagPrefix
} from '@vue-storefront/cache';

export default {
    name: 'CatchAll',
    layout: 'default',
    components: {
        
    },
    head() {

        var meta_image = ''
        if ( this.dynamicPage.meta_image ) {
            meta_image = `${this.image_prefix}c_fill,w_1200,h_630/f_jpg,q_70/${this.dynamicPage.meta_image}`
        }

        return {
            title: this.dynamicPage.meta_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.dynamicPage.meta_description
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: this.dynamicPage.meta_description
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.dynamicPage.meta_title
                },
                {
                  hid: 'og:url',
                  name: 'og:url',
                  content: this.dynamicPage.canonical_url
                },
                {
                  hid: 'og:image',
                  name: 'og:image',
                  content: meta_image
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: this.dynamicPage.canonical_url
                }
            ]
        }
    },
    setup(props, context) {

        const route = context.root.$route

        const { addTags } = useCache();

        // onSSR(() => {
        //     addTags([
        //         { prefix: CacheTagPrefix.View, value: 'page' },
        //         { prefix: CacheTagPrefix.Category, value: route.path },
        //     ]);
        // })

        const { loadDynamicPage, loadDynamicComponents } = useContent()
        const slug = route.path.replace(/^\/+/g, '').replace(/\/$/, "")

        var dynamicPageRef = loadDynamicPage({ identifier: slug })
        var dynamicComponentsRef = loadDynamicComponents

        return {
          dynamicPageRef,
          dynamicComponentsRef
        };
    },
    async fetch() {
        this.dynamicPage = await this.dynamicPageRef
        if ( this.dynamicPage.code && this.dynamicPage.code === 404 ) {
            // this.layout = 'error'
            return this.$nuxt.error({ statusCode: this.dynamicPage.code, message: this.dynamicPage.message })
        } else {
            const components_array = this.dynamicPage.components.map(function(item){
                return item.id
            });
            if ( components_array.length ) {
                const components_string = components_array.join(',')
                this.dynamicComponents = await this.dynamicComponentsRef({ identifier: components_string })

                this.processDynamicContent()
            }
        }
    },
    methods: {
        processDynamicContent() {
            if (this.dynamicComponents && this.dynamicComponents.length) {
                this.dynamicComponents.forEach(element => {
                    this.dynamicContent[element.id] = element.data
                });
            }
        },
    },
    data() {
        return {
            dynamicPage: {},
            dynamicComponents: [],
            dynamicContent: {}
        }
    },
    watch: {
        dynamicPage (new_value, old_value) {
            if ( new_value.title ) {
                this.dynamicPage.breadcrumbs.push({
                    title: new_value.title,
                })

                for (var i in new_value.design_tips_trends) {
                    // new_value.design_tips_trends[i].main_image.src = this.image_prefix + new_value.design_tips_trends[i].main_image.src
                    new_value.design_tips_trends[i].image = new_value.design_tips_trends[i].main_image
                }
            }
        }
    }
}
