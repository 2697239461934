






















  import {
      defineComponent,
      ref,
      useContext,
      onMounted,
      useFetch,
  } from '@nuxtjs/composition-api';

  export default defineComponent({
      name: 'FAQs',
      components: {
          
      },
      setup() {
          
      },
      head() {
          return {
              
          }
      },
      props: {
          data: {
              type: Object,
              default() {
                  return {
                  }
              }
          }
      },
      data() {

          var titleDescriptionData = {}

          if (this.data.introTitle) {
              titleDescriptionData.title = this.data.introTitle
          }
          if (this.data.introDescription) {
              titleDescriptionData.description = this.data.introDescription
          }

          return {
              titleDescriptionData
          }
      }
});
