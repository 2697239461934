import { render, staticRenderFns } from "./FAQs.vue?vue&type=template&id=b4220aee&scoped=true&"
import script from "./FAQs.vue?vue&type=script&lang=ts&"
export * from "./FAQs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4220aee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleDescription: require('/var/www/components/TitleDescription.vue').default,Accordion: require('/var/www/components/Accordion.vue').default})
