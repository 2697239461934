var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"the-team"},[_c('div',{staticClass:"wide-container"},[_c('div',{staticClass:"content-container py-3"},[_c('TitleDescription',{attrs:{"margin":"my-5","titleBorder":false,"data":_vm.data}}),_vm._v(" "),(_vm.data.items.length)?_c('div',{staticClass:"row"},_vm._l((_vm.data.items),function(ref,index){
var name = ref.name;
var role = ref.role;
var description = ref.description;
var links = ref.links;
var image = ref.image;
return _c('div',{key:("member-" + index),staticClass:"item col-12 col-sm-6 col-md-4 position-relative"},[_c('div',{class:[
                            (_vm.hoverItem === index) ? '' : null,
                            (_vm.activeItem === index) ? 'active' : null
                        ],on:{"mouseover":function($event){_vm.hoverItem = index},"mouseleave":function($event){_vm.hoverItem = false}}},[_c('div',{staticClass:"content border-radius p-4",class:[
                                (_vm.hoverItem === index) ? 'shadow light' : null,
                                (_vm.activeItem === index) ? 'shadow light active' : null
                            ],on:{"mouseover":function($event){_vm.hoverItem = index},"mouseleave":function($event){_vm.hoverItem = false}}},[_c('div',{staticClass:"limited mb-3"},[_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"border-radius",attrs:{"src":(_vm.image_prefix + "c_fill,w_159,h_212/f_auto,q_70/" + (image.src)),"alt":image.alt}})]),_vm._v(" "),_c('div',{staticClass:"col-7"},[_c('h6',[_vm._v(_vm._s(name))]),_vm._v(" "),_c('p',{staticClass:"font-13"},[_vm._v(_vm._s(role))]),_vm._v(" "),_c('ul',{staticClass:"list-unstyled list-inline m-0"},_vm._l((links),function(ref,socialIndex){
                            var icon = ref.icon;
                            var url = ref.url;
return _c('li',{key:("member-social-" + socialIndex),staticClass:"list-inline-item m-0"},[_c('a',{attrs:{"href":url,"target":"_blank"}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x larks-stone"}),_vm._v(" "),_c('i',{staticClass:"fa-stack-1x fa-inverse",class:icon})])])])}),0)])]),_vm._v(" "),_c('hr',{staticClass:"separator my-4"}),_vm._v(" "),_c('div',{staticClass:"content font-13",domProps:{"innerHTML":_vm._s(description)}})]),_vm._v(" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeItem !== index),expression:"activeItem !== index"}],staticClass:"font-13 semi-bold text-uppercase hover-larks-stone underline",on:{"click":function($event){_vm.activeItem = index}}},[_vm._v("\n                                Read more\n                            ")]),_vm._v(" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeItem === index),expression:"activeItem === index"}],staticClass:"font-13 semi-bold text-uppercase hover-larks-stone underline",on:{"click":function($event){_vm.activeItem = false}}},[_vm._v("\n                                Close\n                            ")])])])])}),0):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }