

























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'SimpleBanner',
    components: {
        
    },
    inject: [
        'image_format'
    ],
    props: {
        data: {
            type: Object,
            default() {
                return {
                    title: String,
                    description: String
                }
            }
        }
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    mounted() {
        
    },
    destroyed() {
        
    },
    methods: {
        
    }
});
