





















































































































































import { required, email } from "vee-validate/dist/rules";
    extend("email", {
    ...email,
    message: "Invalid email",
});

extend("required", {
    ...required,
    message: "This field is required",
});

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
    SfInput,
    SfRadio,
    SfTexarea,
    SfButton,
    SfCheckbox,
    SfLoader,
} from "@storefront-ui/vue";

import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';
import { useContent } from "~/composables";

export default defineComponent({
    name: 'ContactDetailsForm',
    components: {
        SfInput,
        SfTexarea,
        SfRadio,
        SfButton,
        SfCheckbox,
        SfLoader,
        ValidationObserver,
        ValidationProvider,
    },
    setup(props, context) {
        const { submitContact } = useContent()

        return {
            submitContact
        }
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    title: String,
                    description: String,
                    phone: String,
                    email: String,
                    further_details: String
                }
            }
        }
    },
    data() {
        return {
            showForm: true,
            name: "",
            userEmail: "",
            userPhone: "",
            userEmailErr: false,
            userPhoneErr: false,
            message: "",
            recaptcha: false,
            xLoading: false,
        }
    },
    methods: {
        async formAction(e) {
            let formObject = {};
            e.target.elements.forEach(el => {

                if (
                    el 
                    && el.name
                    && el.value
                    && el.type != 'file'
                    && (
                        el.tagName.toLowerCase() == 'input' 
                        || el.tagName.toLowerCase() == 'textarea' 
                    )
                ) 
                {
                    formObject[el.name] = el.value;
                } 

            });

            formObject['source'] = 'other'

            let files = [];
            const __submitContact = this.submitContact;
            
            // if (e.target.file.files && e.target.file.files.length) {

            //   e.target.file.files.forEach((file, i) => {
                
            //     const r = new FileReader()
            //     r.readAsDataURL(file);
            //     r.onload = function() {
        
            //       files.push(r.result);
        
            //       if (i == e.target.file.files.length - 1) {
        
            //         setTimeout(async () => {
                        
            //           formObject['files'] = files;
            //           console.log(JSON.stringify(formObject))
            //           // const res = await __submitContact({ formObject: formObject })
            //           // console.log(res);  
            //         }, 100);
            //       }
            //     }
            //   });
            // } else {
                
                const res = await __submitContact({ formObject: formObject })
                if (res == 200) {
                    this.showForm = false
                }
                
            // }

        },
        validateField(model, type) {
            switch (type) {
                case "text":
                model;
            }
        },
        onError (error) {
            this.recaptcha = false
        },
        onSuccess (token) {
            this.recaptcha = true
        },
        onExpired () {
            this.recaptcha = false
        }
    }
})
