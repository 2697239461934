






























import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'ImageWave',
    components: {

    },
    data() {
        return {
            image_prefix: null
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    images: {
                        type: Array,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        
    },
});
