








































































import {
    defineComponent,
    ref,
    useContext,
    onMounted,
    useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent ({
    name: 'ImageCopy',
    components: {
        
    },
    inject: [
        'image_format'
    ],
    data() {
        return {
            image_prefix: null
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    props: {
        margin: {
            type: String,
            default: ''
        },
        padding: {
            type: String,
            default: ''
        },
        rowCols: {
            type: Object,
            default () {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 3
                    }
                }
            }
        },
        background: String,
        title: String,
        description: String,
        content: {
            type: Array,
            default() {
                return {
                    image: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    },
                    title: String,
                    description: String,
                    button: String,
                    url: String
                }
            }
        }
    }
});
